const alertToggle = () => {
  const alert = document.querySelector('.alert')
  if (alert) {
    setInterval(() => {
      alert.style.display = 'none'
    }, 3000)
  }
}

const toggleMenu = () => {
  const burger = document.getElementById('burger')
  const menu = document.getElementById('menu')
  if (burger) {
    burger.addEventListener('click', e => {
      e.stopPropagation()
      menu.classList.toggle('translate-x-0')
    })
  }
}
toggleMenu()
alertToggle()
